import './main.scss';

import { loadComponents } from '@opten/helper-components';
import { Component } from '@opten/compiler/metadata/component';
import { CollapseComponent } from '@opten/collapse';
import { PhotoswipeGallery } from '@opten/photoswipe-gallery';
import { SliderComponent } from './components/slider';
import { Navigation } from './components/navigation';
import { CookiebotRenewComponent } from './components/cookiebot';

@Component({ className: 'js-photoswipe-gallery-element', observe: true })
class PhotoswipeGalleryComponent {
	constructor(element: HTMLElement) {
		new PhotoswipeGallery(element);
	}
}

loadComponents(
	CollapseComponent,
	PhotoswipeGalleryComponent,
	SliderComponent,
	Navigation,
	CookiebotRenewComponent
);
